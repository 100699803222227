import TYPES from '@/types';

import Vue from 'vue';

import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import {
  StateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class InvestmentStrategyInitialBalanceViewModel {
  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  readonly recurring_contributions_state_manager!: StateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.initial-balance';

  readonly i18n_form_errors_namespace = 'utils.form-rules';

  readonly view: Vue;

  is_valid_form = false;

  initial_balance_slider = 500;

  min_contribution = 1000;

  initial_balance_min_value = 0;

  initial_balance_max_value = 0;

  remaining_balance = 0;

  show_balance_note = false;

  state: AccessMoreStrategiesState;

  recurring_contributions_state = this.recurring_contributions_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.show_balance_note = this.validateIfShowBalanceNote(this.state);
    this.setMinimumBalance(this.state);
  }

  inputs_rules = {
    initial_balance: [
      requiredRule,
      (value: string) => (
        (this.state.define_contribution_later || parseFloat(value.replace(/[^0-9.]/g, '')) <= this.initial_balance_max_value)
        || this.translate(
          'maximum_error',
          { value: `${currencyFormat(this.initial_balance_max_value)} MXN` },
          this.i18n_form_errors_namespace,
        )
      ),
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1000.00 MXN', this.min_contribution),
    ],
  }

  translate = (message: string, values?: Values, namespace = this.i18n_namespace) => this.translator.translate(`${namespace}.${message}`, values);

  get can_continue() {
    return this.is_valid_form;
  }

  get available_balance_formatted() {
    return currencyFormat(this.state.available_balance);
  }

  get remaining_balance_formatted() {
    return currencyFormat(this.remaining_balance);
  }

  get show_warning_maximum() {
    return parseFloat(this.state.initial_balance
      .replace(/[^0-9.]/g, '')) === this.initial_balance_max_value
      && this.state.strategy.label !== 'SWSRFP';
  }

  getAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.state.customer_agreement.customer_id,
      );
      if (allianz_account_report_list.length) {
        allianz_account_report_list.forEach((position) => {
          if (position.found_code === 'SWSRFP') {
            this.state.available_balance = position.final_balance_mxn || 0;
          }
        });
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_balance'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      const customer_investor_goal_balance = {
        investment_product_id: this.state.strategy.investment_product_id,
        reload: true,
      };
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
        .execute(customer_investor_goal_balance);
      this.state.available_balance = unassigned_balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      } else {
        await this.getAllianzBalance();
      }
    }
  }

  updateAvailableBalance = async () => {
    await this.loadCustomerInvestorGoalBalance();
    this.initial_balance_max_value = this.getMaximumAmountAllowedPerStrategy(this.state);
    const parsed_initial_balance = parseFloat(
      this.state.initial_balance.replace(/[^0-9.]/g, ''),
    );
    this.initial_balance_slider = Math.floor(parsed_initial_balance);
    this.remaining_balance = this.state.available_balance - parsed_initial_balance;
  }

  updateInitialBalance = async () => {
    this.state.initial_balance = this.initial_balance_slider.toString();
    await this.updateAvailableBalance();
  }

  getMaximumAmountAllowedPerStrategy = (state: AccessMoreStrategiesState): number => {
    const rounded_initial_balance = Math.floor(state.available_balance);
    const minimum = state.strategy.label === 'SWSRFP' ? 0 : 500;
    const available = rounded_initial_balance - minimum;
    return available >= minimum ? available : 0;
  }

  validateIfShowBalanceNote = (state: AccessMoreStrategiesState): boolean => (
    state.strategy.label !== 'SWSRFP'
  );

  setMinimumBalance = (state: AccessMoreStrategiesState) => {
    if (state.strategy.label !== 'SWSRFP') {
      this.min_contribution = 1000;
    } else {
      this.min_contribution = 0;
    }
  };

  createBucketWithoutContributions = () => {
    this.view.$emit('createAndLinkGoal');
  }

  validateLimitReached = () => (
    ((this.recurring_contributions_state.item.contributions.length > 0
    && (this.recurring_contributions_state.item.contributions[0].amount + 500) > 50000)
    && (this.recurring_contributions_state.item.contributions.length > 1
    && (this.recurring_contributions_state.item.contributions[1].amount + 500) > 50000))
  )

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    if (this.validateLimitReached()) {
      this.state.contribution_limit_reached = true;
      this.createBucketWithoutContributions();
    } else {
      this.view.$emit('nextStep');
    }
  }

  initialize = async () => {
    await this.updateAvailableBalance();
  }
}
